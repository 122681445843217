import React, { useState, useEffect } from 'react';

const Header = ( { isBioPage, isConcertsPage, isMediaPage, isContactPage, language } ) => {

  const [isVisible, setIsVisible] = useState(true);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);

  const bioLinkStyle = {
    color: isBioPage ? '#ccb25c' : ''
  };

  const concertsLinkStyle = {
    color: isConcertsPage ? '#ccb25c' : ''
  };

  const mediaLinkStyle = {
    color: isMediaPage ? '#ccb25c' : ''
  };

  
  const contactLinkStyle = {
    color: isContactPage ? '#ccb25c' : ''
  };

  useEffect(() => {
    let lastScrollY = window.pageYOffset;
    let ticking = false;
  
    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;
  
      if (!ticking) {
        window.requestAnimationFrame(() => {
          setIsVisible(currentScrollY <= 0 || currentScrollY < lastScrollY);
          lastScrollY = currentScrollY;
          ticking = false;
        });
  
        ticking = true;
      }
    };
  
    window.addEventListener('scroll', handleScroll, { passive: true });
  
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className={`header-overlaid ${!isVisible ? 'header-hidden' : ''}`}>
        <header className="main-header">
          {language === 'en' && (
          <div className='header-name'><a  href='/'><h2 className='name'>Tatu Kauppinen | Cellist</h2></a></div>
          )}
          {language === 'fi' && (
          <div className='header-name header-name-fi'><h2 className='name'><a  href='/fi/kotisivu'>Tatu Kauppinen | Cellist</a></h2></div>
          )}
          {language === 'en' && (
          <div className='navigation-languages-home'>
            <a className=' hover-links links-circle'><span className='color'>EN</span></a>
            <a className='hover-links links-circle' href='/fi/kotisivu'><span>FI</span></a>
          </div>
          )}
          {language === 'fi' && (
          <div className='navigation-languages-home'>
            <a className=' hover-links links-circle' href='/'><span>EN</span></a>
            <a className='hover-links links-circle'><span className='color'>FI</span></a>
          </div>
          )}
          <nav className={`header-navigation ${language === 'fi' ? 'header-navigation-fi' : ''}`}>
            <div className='navigation'>
            {language === 'en' && (
              <>
                <a className={`navigation-links hover-links`} style={bioLinkStyle} href='/bio'>CELLIST</a>
                <a className='navigation-links hover-links' style={concertsLinkStyle} href='/concerts'>CONCERTS</a>
                <a className='navigation-links hover-links' style={mediaLinkStyle} href='/media'>MEDIA</a>
                <a className='navigation-links hover-links' style={contactLinkStyle} href='/contact'>CONTACT</a>
              </>
            )}
            {language === 'fi' && (
              <>
                <a className={`navigation-links hover-links finish`} style={bioLinkStyle} href='/fi/bio'>BIOGRAFIA</a>
                <a className='navigation-links hover-links finish' style={concertsLinkStyle} href='/fi/konsertit'>KONSERTIT</a>
                <a className='navigation-links hover-links finish' style={mediaLinkStyle} href='/fi/media'>MEDIA</a>
                <a className='navigation-links hover-links finish' style={contactLinkStyle} href='/fi/yhteystiedot'>YHTEYSTIEDOT</a>
              </>
              )}
              </div>
              <div className='navigation-extra'>
              <div className='navigation-social-media'>
                <a target="_blank" href='https://www.instagram.com/tatu_kauppinen/' className='navigation-icons hover-links'><svg width="1.8em" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M24.7106 4.4209H10.7106C6.84458 4.4209 3.71057 7.5549 3.71057 11.4209V25.4209C3.71057 29.2869 6.84458 32.4209 10.7106 32.4209H24.7106C28.5766 32.4209 31.7106 29.2869 31.7106 25.4209V11.4209C31.7106 7.5549 28.5766 4.4209 24.7106 4.4209ZM28 9C28 9.55228 27.5523 10 27 10C26.4477 10 26 9.55228 26 9C26 8.44772 26.4477 8 27 8C27.5523 8 28 8.44772 28 9ZM16.0518 13.7604C16.9269 13.3063 17.9229 13.1397 18.8981 13.2843C19.8928 13.4319 20.8138 13.8954 21.5249 14.6065C22.236 15.3176 22.6995 16.2385 22.847 17.2333C22.9916 18.2085 22.825 19.2044 22.371 20.0795C21.9169 20.9546 21.1985 21.6642 20.3179 22.1075C19.4372 22.5507 18.4393 22.705 17.4659 22.5484C16.4926 22.3918 15.5934 21.9322 14.8963 21.2351C14.1992 20.538 13.7396 19.6388 13.583 18.6654C13.4263 17.6921 13.5806 16.6941 14.0239 15.8135C14.4671 14.9329 15.1767 14.2144 16.0518 13.7604ZM19.1366 11.6762C17.8231 11.4814 16.4817 11.7058 15.3031 12.3173C14.1244 12.9289 13.1687 13.8965 12.5717 15.0826C11.9747 16.2686 11.7669 17.6127 11.9779 18.9237C12.1888 20.2347 12.8078 21.4457 13.7467 22.3846C14.6856 23.3236 15.8967 23.9425 17.2076 24.1535C18.5186 24.3644 19.8627 24.1566 21.0488 23.5596C22.2348 22.9627 23.2025 22.0069 23.814 20.8283C24.4256 19.6497 24.6499 18.3082 24.4551 16.9948C24.2565 15.655 23.6322 14.4146 22.6744 13.4569C21.7167 12.4992 20.4763 11.8749 19.1366 11.6762Z" fill="currentColor"></path></svg>
                </a>
                <a target="_blank" href='https://www.facebook.com/tatu.kauppinen.3' className='navigation-icons hover-links'><svg width="1.8em" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26.7106 4.46777H22.3469C20.4181 4.46777 18.5682 5.20527 17.2043 6.51803C15.8404 7.83078 15.0742 9.61126 15.0742 11.4678V15.6678H10.7106V21.2678H15.0742V32.4678H20.8924V21.2678H25.256L26.7106 15.6678H20.8924V11.4678C20.8924 11.0965 21.0456 10.7404 21.3184 10.4778C21.5912 10.2153 21.9612 10.0678 22.3469 10.0678H26.7106V4.46777Z" fill="currentColor"></path></svg>
                </a>
                <a target="_blank" href='' className='navigation-icons hover-links' ><svg width="1.8em" viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M34.0203 10.1068C33.8339 9.41212 33.4662 8.77811 32.9542 8.26823C32.4421 7.75835 31.8036 7.3905 31.1026 7.20152C28.5299 6.51465 18.2106 6.51465 18.2106 6.51465C18.2106 6.51465 7.8912 6.51465 5.3172 7.20152C4.61651 7.39083 3.97835 7.75882 3.46655 8.26866C2.95476 8.77851 2.58726 9.41235 2.40082 10.1068C1.71057 12.6693 1.71057 18.0146 1.71057 18.0146C1.71057 18.0146 1.71057 23.36 2.40082 25.9225C2.58725 26.6172 2.95491 27.2512 3.46698 27.7611C3.97906 28.2709 4.61757 28.6388 5.31857 28.8278C7.8912 29.5147 18.2106 29.5146 18.2106 29.5146C18.2106 29.5146 28.5299 29.5147 31.1039 28.8278C31.805 28.6389 32.4436 28.2711 32.9557 27.7612C33.4678 27.2513 33.8354 26.6172 34.0217 25.9225C34.7106 23.36 34.7106 18.0146 34.7106 18.0146C34.7106 18.0146 34.7106 12.6693 34.0203 10.1068ZM14.8349 22.8676V13.1617L23.4603 18.0146L14.8349 22.8676Z" fill="currentColor"></path></svg>
                </a>
              </div>
            </div>
          </nav>
          <div className='hamburger-container'><img className='hamburger' src="../pictures/icons8-hamburger-50.png"/></div>
        </header>
      </div>
    </>
  );
};

export default Header;