import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import MenuHamburger from "../components/MenuHamburger"
import Footer from "../components/Footer";
import { Link } from "react-scroll";
import { Helmet } from 'react-helmet'


export const BioFI = () => {

  const [loaded, setLoaded] = useState(false);
  const [language, setLanguage] = useState("fi");
  const [showMenuHamburger, setShowMenuHamburger] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showMobile, setShowMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setShowMobile(false);
      } else {
        setShowMobile(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1023) {
        setShowMenuHamburger(false);
      } else {
        setShowMenuHamburger(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, []);
  
  const imageStyle = {
    opacity: loaded ? 1 : 0,
    transform: loaded ? 'scale(1)' : 'scale(1.3)',
  };

  return (
    <>
    <Helmet>
      <title>Tatu Kauppinen | Biografia</title>
      <meta name="description" content="Tatu Kauppisen Biografia" />
    </Helmet>
    <div className="bio-container">
      {showMenuHamburger && <MenuHamburger isMenuOpen={isMenuOpen} isBioPage={true} language={language} />}
      {!showMenuHamburger && <Header isBioPage={true} language={language}/>}
      <div className="main-bio">
        <div className="image-container">
        {!showMobile ? (
            <img 
              style={imageStyle}
              className="bio-image"
              src="https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/_AMA5086.jpg"
              onLoad={() => setLoaded(true)}
              />
              ) : (
            <img 
            className="bio-image-phone" 
            src="../photos/_AMA5174.jpg"
            />
      )}
        </div>
        <div className="bio-quote-wrapper">
          <blockquote className="home-quote bio">“Lorem ipsum dolor sit amet, consectetur adipiscing elit.”</blockquote>
          <blockquote className="quoter bio"> Boethius</blockquote>
        </div>
        <Link id="scrollTarget" to="scrollTarget" smooth={true} duration={1000} className="down-arrow-link">
        <div className="down-arrow-bio">
          <svg className="down-arrow" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M24 12c0-6.623-5.377-12-12-12s-12 5.377-12 12 5.377 12 12 12 12-5.377 12-12zm-1 0c0-6.071-4.929-11-11-11s-11 4.929-11 11 4.929 11 11 11 11-4.929 11-11zm-11.5 4.828l-3.763-4.608-.737.679 5 6.101 5-6.112-.753-.666-3.747 4.604v-11.826h-1v11.828z"/></svg>
        </div>
        </Link>
        <div className="main-bio-container">
          <h1 className="bio-heading">Biografia</h1>
          <div className="bio-wrapper">
            <p className="bio-text">
            Tatu Kauppinen (s. 2000) on sukupolvensa kysytyimpiä sellistejä Suomessa. Tällä hetkellä hän asuu Berliinissä ja opiskelee maineikkaassa Berliinin Filharmonikkojen Karajan-orkesteriakatemiassa. Tatu Kauppinen aloitti sellonsoiton 7-vuotiaana Keski-Helsingin musiikkiopistossa. Varsin pian hän siirtyi Sibelius-Akatemian nuorisokoulutuksen oppilaaksi ja sieltä edelleen Sibelius-Akatemiaan jatkamaan solistisia opintojaan. Karajan-Akatemiassa hän aloitti helmikuussa 2023, ja siellä häntä ohjaa Martin Löhr. Kauppinen soittaa yksityishenkilön hänelle ystävällisesti lainaamaa Pierre & Hippolyte Silvestre-selloa vuodelta 1838. Hänen jousensa on Finlandia Hall Rotaryklubin omistama Husson Mariet.
            </p>  
            <p className="bio-text">
              Tatu Kauppinen opiskelee myös Hochschule für Musik und Theater Rostock- taideyliopistossa opettajanaan professori Natalie Clein. Aikaisempia opettajia ovat olleet Helli Seppä, Tuomas Lehto, Hannu Kiiski ja professori Marko Ylönen. Kauppinen voitti ensimmäisen palkinnon Turun sellokilpailussa vuonna 2022. Hänet palkittiin myös yleisöpalkinnolla sekä erikoispalkinnolla parhaasta kilpailun tilausteoksen Olli Virtaperkon ”Sitkan” esityksestä. 
            </p>  
            <p className="bio-text">      
              Tatu Kauppinen on esiintynyt muun muassa Turun filharmonisen orkesterin, Norddeutsche Philharmonie Rostockin, Joensuun Kaupunginorkesterin, Uuden Ajan Ensemblen ja Keski-Pohjanmaan kamariorkesterin solistina. Turun filharmonisen orkesterin solistiksi Kauppinen palaa itsenäisyyspäivän juhlakonsertissa.
            </p> 
            <p className="bio-text">
              Omistautuneena kamarimuusikkona tunnettu Kauppinen on esiintynyt useilla festivaaleilla eri puolilla Suomea ja Eurooppaa, kuten Kuhmon Kamarimusiikissa, Naantalin musiikkijuhlilla ja Festival de Pâques Aix-en-Provence- festivaalilla. Vuodesta 2023 alkaen hän on esiintynyt säännöllisesti pianisti David Munk-Nielsenin kanssa. Yhdessä Rebecca Roozemanin, Amanda Ernesaksin ja Kristian Sallisen kanssa hän perusti vuonna 2015 Seele-jousikvartetin, joka on menestynyt kilpailuissa niin Suomessa kuin ulkomaillakin. Seele-kvartetti on esiintynyt Kauniaisten Musiikkijuhlien residenssiyhtyeenä usean vuoden ajan. 
            </p>
            <p className="bio-text">
              Berliinin filharmonikkojen lisäksi Tatu Kauppinen on soittanut muun muassa Helsingin kaupunginorkesterissa sijaistaen 2. soolosellistin tehtävää. Vuonna 2023 hän liittyi Suomalaiseen Kamariorkesteriin. 
            </p>
            <a  href="https://dl.dropboxusercontent.com/scl/fi/zz5b417cw23v99csa19ar/tatu_kauppinen_presskit.zip?rlkey=12zz0uaxof2slzlq4p7p137yc&dl=0" className="contact-links-container fit-content bio-download" target="_blank">
              <span className='download-presskit navigation-icons hover-links links-contact' ><svg width="1.7em" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 17L12 20M12 20L15 17M12 20V12M4 15.6458C3.70865 15.3154 3.47348 14.9387 3.30448 14.5307C3.10346 14.0454 3 13.5253 3 13C3 12.4747 3.10346 11.9546 3.30448 11.4693C3.5055 10.984 3.80014 10.543 4.17157 10.1716C4.54301 9.80014 4.98396 9.5055 5.46927 9.30448C5.95457 9.10346 6.47471 9 7 9C7 8.34339 7.12933 7.69321 7.3806 7.08658C7.63188 6.47996 8.00017 5.92876 8.46447 5.46447C8.92876 5.00017 9.47996 4.63188 10.0866 4.3806C10.6932 4.12933 11.3434 4 12 4C12.6566 4 13.3068 4.12933 13.9134 4.3806C14.52 4.63188 15.0712 5.00017 15.5355 5.46447C15.9998 5.92876 16.3681 6.47996 16.6194 7.08658C16.8707 7.69321 17 8.34339 17 9C17.5253 9 18.0454 9.10346 18.5307 9.30448C19.016 9.5055 19.457 9.80014 19.8284 10.1716C20.1999 10.543 20.4945 10.984 20.6955 11.4693C20.8965 11.9546 21 12.4747 21 13C21 13.5253 20.8965 14.0454 20.6955 14.5307C20.5265 14.9387 20.2913 15.3154 20 15.6458" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg></span>
              <span className='hover-links links-contact margin download-presskit'>Download Biography</span>
            </a>  
          </div>
        </div>
      </div>
      <Footer/>
    </div>
    </>
  );
};

export default BioFI;
