import React, { useState, useEffect } from 'react';

const Footer = ( {setFooterClass} ) => {

    const footerClass = setFooterClass ? 'footer bottom-zero' : 'footer';

  return (
    <>
        <div className={footerClass}>
            <div className='footer-container'>
                {/*  <div className='imprint-container'>
                    <a href=''><div className='color-a hover-links'>Imprint</div></a>
                    <div className='padding-line'>|</div>
                    <a href=''><div className='color-a hover-links'>Privacy Policy</div></a>
                </div> */}
                <div className='tatu-copyrights'>Tatu Kauppinen</div>
                <div className='tatu-copyrights'>All rights reserved @2023</div>
                <div className='made-by'>
                    <span>Made By</span>
                    <span>Me</span>
                </div>
            </div>
        </div>

    </>
  );
};

export default Footer;