import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Form} from "react-router-dom";
import './App.css';
import { Home } from "./pages/home";
import { Bio } from "./pages/bio";
import { BioFI } from "./pages/bioFI";
import { Concerts } from "./pages/concerts";
import {  Media } from "./pages/media";
import {  Contact } from "./pages/contact";
import { HomeFI } from "./pages/homeFI"
import { ConcertsFI } from "./pages/concertsFI"
import { ContactFI } from "./pages/contactFI"
import {  MediaFI } from "./pages/mediaFI";

function App() {
  const location = useLocation()

  return (
    <div className="App">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={ <Home/>}/>
        <Route path="/bio" element={ <Bio/>}/>
        <Route path="/fi/bio" element={ <BioFI/>}/>
        <Route path="/concerts" element={ <Concerts/>}/>
        <Route path="/media" element={ <Media/>}/>
        <Route path="/contact" element={ <Contact/>}/>
        <Route path="/fi/kotisivu" element={ <HomeFI/>}/>
        <Route path="/fi/konsertit" element={ <ConcertsFI/>}/>
        <Route path="/fi/yhteystiedot" element={ <ContactFI/>}/>
        <Route path="/fi/media" element={ <MediaFI/>}/>
      </Routes>
    </div>
  );
}

export default App;