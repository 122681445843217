import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import MenuHamburger from "../components/MenuHamburger"
import Footer from "../components/Footer";
import { Link } from "react-scroll";
import { Helmet } from 'react-helmet'

export const Bio = () => {

  const [loaded, setLoaded] = useState(false);
  const [language, setLanguage] = useState("en");
  const [showMenuHamburger, setShowMenuHamburger] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showMobile, setShowMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setShowMobile(false);
      } else {
        setShowMobile(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1023) {
        setShowMenuHamburger(false);
      } else {
        setShowMenuHamburger(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, []);
  
  const imageStyle = {
    opacity: loaded ? 1 : 0,
    transform: loaded ? 'scale(1)' : 'scale(1.3)',
  };

  return (
    <>
    <Helmet>
      <title>Tatu Kauppinen | Biography</title>
      <meta name="description" content="Biography of Tatu Kauppinen" />
    </Helmet>
    <div className="bio-container">
      {showMenuHamburger && <MenuHamburger isMenuOpen={isMenuOpen} isBioPage={true} language={language} />}
      {!showMenuHamburger && <Header isBioPage={true} language={language}/>}
      <div className="main-bio">
        <div className="image-container">
        {!showMobile ? (
            <img 
              style={imageStyle}
              className="bio-image"
              src="https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/_AMA5086.jpg"
              onLoad={() => setLoaded(true)}
              />
              ) : (
            <img 
            className="bio-image-phone" 
            src="../photos/_AMA5174.jpg"
            />
      )}
        </div>
        {/* <div className="bio-quote-wrapper">
          <blockquote className="home-quote bio">“Lorem ipsum dolor sit amet, consectetur adipiscing elit.”</blockquote>
          <blockquote className="quoter bio"> Boethius </blockquote>
        </div> */}
        <Link id="scrollTarget" to="scrollTarget" smooth={true} duration={1000} className="down-arrow-link">
        <div className="down-arrow-bio">
          <svg className="down-arrow" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M24 12c0-6.623-5.377-12-12-12s-12 5.377-12 12 5.377 12 12 12 12-5.377 12-12zm-1 0c0-6.071-4.929-11-11-11s-11 4.929-11 11 4.929 11 11 11 11-4.929 11-11zm-11.5 4.828l-3.763-4.608-.737.679 5 6.101 5-6.112-.753-.666-3.747 4.604v-11.826h-1v11.828z"/></svg>
        </div>
        </Link>
        <div className="main-bio-container">
          <h1 className="bio-heading">Biography</h1>
          <div className="bio-wrapper">
            <p className="bio-text">
              Tatu Kauppinen, born in 2000, stands as one of the most prominent Finnish cellists of his generation. His musical journey has brought him into the prestigious Karajan Academy of the Berlin Philharmonic, where he started his studies in February 2023.
            </p>  
            <p className="bio-text">
              Tatu Kauppinen won the first prize in Finland's nationally renowned Turku Cello Competition in 2022. He was also awarded the audience prize and a special prize for the best performance of the competition's commissioned piece, Olli Virtaperko's "Sitka”. Known as a dedicated and passionate chamber musician, he has performed at various festivals across Finland and Europe, including the Kuhmo Chamber Music Festival, Festival de Pâques Aix-en-Provence, and Naantali Music Festival.
            </p>  
            <p className="bio-text">
              Tatu grew up in a family of musicians and started his cello studies at the age of 7 at the Central Helsinki Music Institute. From there he continued to the Sibelius Junior Academy, and finally the Sibelius Academy's Bachelor programme. In addition to being a scholarship holder at the Karajan Academy and being mentored there by Martin Löhr, he is studying at the Hochschule für Musik und Theater Rostock under the guidance of Prof. Natalie Clein. His previous teachers include Helli Seppä, Tuomas Lehto, Hannu Kiiski, and Marko Ylönen. He has been deeply influenced by masterclasses with artists such as Arto Noras, Ola Karlsson, Nils Mönkemeyer, and the Meta4 Quartet.
            </p> 
            <p className="bio-text">
              As a soloist, he has appeared with orchestras such as the Turku Philharmonic Orchestra, Norddeutsche Philharmonie Rostock, Ostrobothnian Chamber Orchestra and Joensuu City Orchestra. In the fall of 2024, he will perform as soloist with the Karajan Academy orchestra in Berlin.
            </p>
            <p className="bio-text">
              In addition to regularly playing in the cello group of the Berlin Philharmonic, Tatu has played with the Helsinki Philharmonic Orchestra and the Finnish Radio Orchestra, substituting for the position of principal cellist in both. He plays a Pierre & Hippolyte Silvestre cello from 1838, generously loaned to him by a private donor.
            </p>
            <a  href="https://dl.dropboxusercontent.com/scl/fi/zz5b417cw23v99csa19ar/tatu_kauppinen_presskit.zip?rlkey=12zz0uaxof2slzlq4p7p137yc&dl=0" className="contact-links-container fit-content bio-download" target="_blank">
              <span className='download-presskit navigation-icons hover-links links-contact' ><svg width="1.7em" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 17L12 20M12 20L15 17M12 20V12M4 15.6458C3.70865 15.3154 3.47348 14.9387 3.30448 14.5307C3.10346 14.0454 3 13.5253 3 13C3 12.4747 3.10346 11.9546 3.30448 11.4693C3.5055 10.984 3.80014 10.543 4.17157 10.1716C4.54301 9.80014 4.98396 9.5055 5.46927 9.30448C5.95457 9.10346 6.47471 9 7 9C7 8.34339 7.12933 7.69321 7.3806 7.08658C7.63188 6.47996 8.00017 5.92876 8.46447 5.46447C8.92876 5.00017 9.47996 4.63188 10.0866 4.3806C10.6932 4.12933 11.3434 4 12 4C12.6566 4 13.3068 4.12933 13.9134 4.3806C14.52 4.63188 15.0712 5.00017 15.5355 5.46447C15.9998 5.92876 16.3681 6.47996 16.6194 7.08658C16.8707 7.69321 17 8.34339 17 9C17.5253 9 18.0454 9.10346 18.5307 9.30448C19.016 9.5055 19.457 9.80014 19.8284 10.1716C20.1999 10.543 20.4945 10.984 20.6955 11.4693C20.8965 11.9546 21 12.4747 21 13C21 13.5253 20.8965 14.0454 20.6955 14.5307C20.5265 14.9387 20.2913 15.3154 20 15.6458" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg></span>
              <span className='hover-links links-contact margin download-presskit'>Download Biography</span>
            </a>  
          </div>
        </div>
      </div>
      <Footer/>
    </div>
    </>
  );
};

export default Bio;
