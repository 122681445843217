import React from 'react';

function ConcertItem({ day, month, city, place }) {
  return (
    <a className="concerts-anchors" href="/concerts">
      <div className="home-concert">
        <div className="concert-date">
          <span className="concert-day items">{day}</span>
          <span className="concert-month items">{month}</span>
        </div>
        <div className="concert-location-container">
          <div className="city-concert items">{city}</div>
          <div className="place-concert items">{place}</div>
        </div>
      </div>
    </a>
  );
}

export default ConcertItem;
