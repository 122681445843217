import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import YoutubeEmbed from "../components/YoutubeEmbed";
import MenuHamburger from "../components/MenuHamburger"
import Footer from "../components/Footer";
import { Helmet } from 'react-helmet'
import ImageCarousel from "../components/ImageCarousel"

export const MediaFI = () => {
    
    const [activeContent, setActiveContent] = useState("photos"); 
    const [mediaLoaded, setMediaLoaded] = useState(false);
    const [photosVisible, setPhotosVisible] = useState(false);
    const [videosVisible, setVideosVisible] = useState(false); 
    const [showMenuHamburger, setShowMenuHamburger] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [language, setLanguage] = useState("fi");
    const [selectedImage, setSelectedImage] = useState(null);
    const [isCarouselVisible, setCarouselVisibility] = useState(true);

    const photos = [
      {id:1, src:"../photos/_AMA5393-Edit kopio.jpg" },
      {id:2, src:"../photos/_AMA5398.jpg" },
      {id:3, src: "../photos/_AMA5447.jpg" },  
      {id:4, src:"../photos/_AMA5018.jpg " },
      {id:5, src:"https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/_AMA5086.jpg" },
      {id:6, src:"../photos/_AMA5174.jpg" },
      {id:7, src:"https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/cello_on.jpg" },
      {id:8, src: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/_AMA5467.jpg" },
      {id:9, src: "../photos/_AMA5393-Edit kopio-2.jpg" },
      {id:10, src: "https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/_AMA5460.jpg" },
    ];
    

    const handleImageSelect = (image) => {
      setSelectedImage(image);
    };

    
    const handleCloseClick = () => {
      setCarouselVisibility(false);
    };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1023) {
        setShowMenuHamburger(false);
      } else {
        setShowMenuHamburger(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    const handleContentChange = (content) => {
        setActiveContent(content);
        setPhotosVisible(false);
        setVideosVisible(false);
    };
        
    const handleMediaLoaded = () => {
        setMediaLoaded(true);
    };
    
    useEffect(() => {
        if (activeContent === "photos") {
          setTimeout(() => {
            setPhotosVisible(true);
          }, 500);
        } else if (activeContent === "videos") {
          setTimeout(() => {
            setVideosVisible(true);
          }, 1500);
        }
      }, [activeContent]);    

    return (
      <>
      <Helmet>
        <title>Tatu Kauppinen | Media</title>
        <meta name="description" content="Media - kuvia - videot" />
      </Helmet>
        <div className="media-container">
          {showMenuHamburger && <MenuHamburger isMenuOpen={isMenuOpen} isMediaPage={true} language={language}/>}
          {!showMenuHamburger && <Header isMediaPage={true} language={language} />}
          <div className="media-body">
            <div className="concert-upcoming-container">
                <h1 className="media-heading">Media</h1>
            </div>
            <div className="media-wrapper">
                <div className="button-container">
                    <a className={`btn-media ${activeContent === "photos" ? "active" : ""}`}
                    onClick={() => handleContentChange("photos")} >KUVIA</a>
                    <a className={`btn-media ${activeContent === "videos" ? "active" : ""}`}
                    onClick={() => handleContentChange("videos")} >VIDEOT</a>
                </div>
                <a  href="https://dl.dropboxusercontent.com/scl/fi/zz5b417cw23v99csa19ar/tatu_kauppinen_presskit.zip?rlkey=12zz0uaxof2slzlq4p7p137yc&dl=0" className="contact-links-container fit-content" target="_blank">
                        <span className='download-presskit navigation-icons hover-links links-contact' ><svg width="1.7em" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 17L12 20M12 20L15 17M12 20V12M4 15.6458C3.70865 15.3154 3.47348 14.9387 3.30448 14.5307C3.10346 14.0454 3 13.5253 3 13C3 12.4747 3.10346 11.9546 3.30448 11.4693C3.5055 10.984 3.80014 10.543 4.17157 10.1716C4.54301 9.80014 4.98396 9.5055 5.46927 9.30448C5.95457 9.10346 6.47471 9 7 9C7 8.34339 7.12933 7.69321 7.3806 7.08658C7.63188 6.47996 8.00017 5.92876 8.46447 5.46447C8.92876 5.00017 9.47996 4.63188 10.0866 4.3806C10.6932 4.12933 11.3434 4 12 4C12.6566 4 13.3068 4.12933 13.9134 4.3806C14.52 4.63188 15.0712 5.00017 15.5355 5.46447C15.9998 5.92876 16.3681 6.47996 16.6194 7.08658C16.8707 7.69321 17 8.34339 17 9C17.5253 9 18.0454 9.10346 18.5307 9.30448C19.016 9.5055 19.457 9.80014 19.8284 10.1716C20.1999 10.543 20.4945 10.984 20.6955 11.4693C20.8965 11.9546 21 12.4747 21 13C21 13.5253 20.8965 14.0454 20.6955 14.5307C20.5265 14.9387 20.2913 15.3154 20 15.6458" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg></span>
                        <span className='hover-links links-contact margin download-presskit'>Lataa kuvapaketti</span>
                </a>  
                {activeContent === "photos" && (
                  <div className={`photos-container ${photosVisible ? "visible" : ""}`}>
                    {photos.map((image) => (
                      <img
                        key={image.id}
                        loading="lazy"
                        src={image.src}
                        onClick={() => {
                          setSelectedImage(image);
                          setCarouselVisibility(true);
                        }}                  
                      />
                    ))}
                  </div>
                )}
                {activeContent === "videos" && (
                <div className={`videos-container ${videosVisible ? "visible" : ""}`}>
                    {/* <YoutubeEmbed onLoad={handleMediaLoaded} 
                    title="Dvorák: Cello Concerto in B Minor, Marinsky Philarmonie" 
                    embedId="rokGy0huYEA" />
                    <YoutubeEmbed onLoad={handleMediaLoaded} 
                    title="Dvorák: Cello Concerto in B Minor, Marinsky Philarmonie"
                    embedId="9Z6b-jfdJlw" />
                    <YoutubeEmbed onLoad={handleMediaLoaded} 
                    title="Dvorák: Cello Concerto in B Minor, Marinsky Philarmonie"
                    embedId="Oflbho9ZG2U" />
                    <YoutubeEmbed onLoad={handleMediaLoaded} 
                    title="Dvorák: Cello Concerto in B Minor, Marinsky Philarmonie"
                    embedId="rokGy0huYEA" /> */}
                </div>
                )}
            </div>
          </div>
          {selectedImage && isCarouselVisible && (
          <ImageCarousel 
            selectedImage={selectedImage} 
            photos={photos} 
            onCloseClick={handleCloseClick}
          />
          )}
        <Footer />
      </div>
    </>
  );
};

export default MediaFI;