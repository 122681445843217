import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import CelloLoader from "../components/CelloLoader";
import MenuHamburger from "../components/MenuHamburger"
import ConcertItem from "../components/ConcertHome"
import Footer from "../components/Footer";
import { Helmet } from 'react-helmet'


export const HomeFI = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [addCelloClass, setAddCelloClass] = useState(false);
  const [hideLoader, setHideLoader] = useState(false);
  const [showMenuHamburger, setShowMenuHamburger] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showPortrait, setShowPortrait] = useState(false);
  const [language, setLanguage] = useState("fi");
  const [showMobile, setShowMobile] = useState(false);
  const [isFooterBottomZero, setIsFooterBottomZero] = useState(false);


    const imageClass = showPortrait ? 'background-image2' : 'background-image';

    
    const setFooterClass = (bottomZero) => {
      setIsFooterBottomZero(bottomZero);
    };

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth > 530) {
          setShowMobile(false);
        } else {
          setShowMobile(true);
        }
      };

      handleResize();
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

  useEffect(() => {
    const loaderTimeout = setTimeout(() => {
      setShowLoader(false);
      setAddCelloClass(true);
    }, 3000);

    // Set another timeout to hide the loader after 4.5 seconds
    const hideLoaderTimeout = setTimeout(() => {
      setHideLoader(true);
    }, 3800);

    return () => {
      clearTimeout(loaderTimeout);
      clearTimeout(hideLoaderTimeout);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1023) {
        setShowMenuHamburger(false);
      } else {
        setShowMenuHamburger(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setShowPortrait(false);
      } else {
        setShowPortrait(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
    <Helmet>
      <title>Tatu Kauppinen | Cellist</title>
      <meta name="description" content="Tatu Kauppinen" />
    </Helmet>
    <div className="home-container">
      {!hideLoader && <CelloLoader additionalClass={addCelloClass ? "hidden" : "visible"} />}
      {showMenuHamburger && <MenuHamburger isMenuOpen={isMenuOpen} language={language}/>}
      {!showMenuHamburger && <Header language={language}/>}
      <div className="image-wrapper">
      {!showMobile ? (
            <img className={imageClass} loading="lazy" src="https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/_AMA5460.jpg" />
              ) : (
            <img loading="lazy" className="background-image3" src="https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/_AMA5467.jpg" />
      )}
      </div>
      {/* <div className="quote-wrapper">
        <blockquote className="home-quote">“Lorem ipsum dolor sit amet, consectetur adipiscing elit.”</blockquote>
        <blockquote className="quoter"> Boethius </blockquote>
      </div> */}
      <div className="home-concerts-container">
        <ConcertItem day="09" month="elokuu" city="Juuka" place="Juuan kirkossa" />
        <ConcertItem day="11" month="elokuu" city="Turku" place="Taiteen talossa"/>
        <ConcertItem day="29" month="elokuu" city="Swanage" place="St Mary’s Church"/>
        <a className="home-upcoming-events-container" href='/fi/konsertit'>
          <div className="home-upcoming-events margin-small">Kaikki tulevat konsertit</div>
          <svg className="home-upcoming-events" width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L7 6.5L1 12" stroke="currentColor" strokeWidth="1.5"/>
          </svg>
        </a>
      </div>
      <div className="home-concerts-container-sizes">
          <div className="size-upcoming-concerts-container">
            <a className="home-upcoming-events-container" href='/fi/konsertit'>
              <div className="home-upcoming-events margin-small">Kaikki tulevat konsertit</div>
              <svg className="home-upcoming-events" width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L7 6.5L1 12" stroke="currentColor" strokeWidth="1.5"/>
              </svg>
            </a>
          </div>
          <div className="concerts-container-sizes">
            <ConcertItem day="09" month="elokuu" city="Juuka" place="Juuan kirkossa" />
            <ConcertItem day="11" month="elokuu" city="Turku" place="Taiteen talossa"/>
            <ConcertItem day="29" month="elokuu" city="Swanage" place="St Mary’s Church"/>
          </div>
      </div>
      {hideLoader && <Footer setFooterClass={setFooterClass}/>}
    </div>
    </>
  );
};

export default HomeFI;
