import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import MenuHamburger from "../components/MenuHamburger"
import Footer from "../components/Footer";
import { Helmet } from 'react-helmet'

export const ConcertsFI = () => {

    const [showMenuHamburger, setShowMenuHamburger] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [language, setLanguage] = useState("fi");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1023) {
        setShowMenuHamburger(false);
      } else {
        setShowMenuHamburger(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
    <Helmet>
      <title>Tatu Kauppinen | Konsertit</title>
      <meta name="description" content="Tulevat konsertit" />
    </Helmet>
    <div className="concert-container">
        {showMenuHamburger && <MenuHamburger isMenuOpen={isMenuOpen} isConcertsPage={true} language={language} />}
        {showMenuHamburger && <MenuHamburger isMenuOpen={isMenuOpen} language={language}/>}
        {!showMenuHamburger && <Header isConcertsPage={true} language={language}/>}
       <div className="calendar-body">
            <div className="concert-upcoming-container">
                <h1 className="concert-heading">Tulevat konsertit</h1>
            </div>
            <div className="calendar-wrapper">
                <div className="concert-calendar-container">
                    <div className="calendar-image-container">
                        <img className="calendar-image" loading="lazy" src="https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/cello_on.jpg" />
                    </div>
                    <div className="concert-wrapper">
                        <h3 className="concert-name"> Juuan musiikkijuhlat</h3>
                        <div className="calendar-date-container">
                            <time className="calendar-date">09/08/2024, klo 19:00</time>
                        </div>
                        <div className="concert-location">
                            <h3 className="concert-place2">Juuan kirkossa</h3>
                            <h3 className="concert-place title-margin margin-small">|</h3>
                            <h3 className="concert-place">Juuka, FI</h3>
                        </div>
                        {/* <hr></hr>
                        <div className="players-container">
                            <p>Reinhard Goebel, kapellimestari</p>
                            <p>Karajan Academy</p>
                        </div> */}
                        <a target="_blank" className="button-more" href="https://www.juuanmusiikkijuhlat.fi/ohjelma/">
                            Lue lisää ohjelmasta täältä
                        </a>
                    </div>
                </div>
            </div>
            <div className="calendar-wrapper">
                <div className="concert-calendar-container">
                    <div className="calendar-image-container">
                        <img className="calendar-image external" loading="lazy" src="https://turunmusiikkijuhlat.fi/wp-content/uploads/2023/12/nephesh-1920-x-1080.png" />
                    </div>
                    <div className="concert-wrapper">
                        <h3 className="concert-name">Turun musiikkijuhlat</h3>
                        <div className="calendar-date-container">
                            <time className="calendar-date">11/08/2024, klo 17:00</time>
                        </div>
                        <div className="concert-location">
                            <h3 className="concert-place2">Taiteen talossa</h3>
                            <h3 className="concert-place title-margin margin-small">|</h3>
                            <h3 className="concert-place">Turku, FI</h3>
                        </div>
                        <hr></hr>
                        <div className="players-container">
                            <p>Seele-kvartetti</p>
                        </div>
                        <a className="button-more" href="https://turunmusiikkijuhlat.fi/tapahtuma/nephesh">
                            Lue lisää ohjelmasta täältä
                        </a>
                    </div>
                </div>
            </div>
            <div className="calendar-wrapper last">
                <div className="concert-calendar-container">
                    <div className="calendar-image-container">
                        <img className="calendar-image" loading="lazy" src="https://demetrios-antoniades-nextjs-images-api.s3.eu-north-1.amazonaws.com/cello_on.jpg" />
                    </div>
                    <div className="concert-wrapper">
                        <h3 className="concert-name"></h3>
                        <div className="calendar-date-container">
                            <time className="calendar-date">29/08/2024 - 01/09/2024</time>
                        </div>
                        <div className="concert-location">
                            {/* <h3 className="concert-place">Kuhmo-Talo</h3>
                            <h3 className="concert-place title-margin margin-small">|</h3> */}
                            <h3 className="concert-place">Purbeck, ENG</h3>
                        </div>
                        <a target="_blank" className="button-more" href="https://picmf.org">
                        Lue lisää ohjelmasta täältä
                        </a>
                    </div>
                </div>
            </div>
       </div>
       <Footer/>
    </div>
    </>
  );
};

export default ConcertsFI;
